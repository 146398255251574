.slider{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 1;
  z-index: 9999;
  display:flex;
  align-items:center;
  justify-content:center;
}
.slider-enter {
  transform: translate(0, 0);
}
.slider-enter-active {
  transform: translate(0, -100vh);
  transition: transform 1000ms ease-out 1000ms;
}
.slider-exit {
  transform: translate(0, 0);
}
.slider-exit-active {
  transform: translate(0, 0vh);
  transition: transform 1000ms ease-out 1000ms;
}
.slider-enter-done{
  opacity: 0;
  display: none;
}
